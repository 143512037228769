:root {
    --dropdown-width: 100px;
    --dropdown-background: rgba(37, 37, 37, 0.6);
    --dropdown-color: #3c3c3c;
    --primary-bg: #242526;
    --secondary-bg: #3c3c3c;
    --primary-text-color: #ffffff;
    --secondary-text-color: #d7d7d7;
    --border-radius: 10px;
    --speed: 500ms;
}

header {
    display: block;
    position: absolute;
    z-index: 3;
    width: 100%;
}

header img {
    width: 90px;
    z-index: 5;
    display: block;
    position: absolute;
    margin: 5px 10px 0 20px;
    float: left;
}

header.changeBackground {
    background: rgba(42, 41, 41, 0.90) !important;
}

header .mobile {
    display: none;
}

header .mobile .icon {
    width: 25px;
    display: block;
    position: absolute;
    margin-right: 15px;
    margin-top: 35px;
    right: 0;
    top: 0;
}

header .mobile .items {
    position: absolute;
    margin: 100px 0 0 0;
    width: 100vw;
}

header .mobile .items.active {
    display: block !important;
}

header .mobile .items ul {
    width: 100vw;
    display: block;
    position: relative;
    list-style: none;
    margin-right: 0;
    margin-left: 0;
    padding-bottom: 40px;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    padding-inline-start: 0 !important;
}

header .mobile .items ul li {
    text-align: center;
    font-size: 20px;
    padding: 7px 0;
}

header .mobile ul div.line {
    display: block;
    vertical-align: middle;
    height: 3px;
    width: 3rem;
    background: #5e5e5f;
    border-radius: 10px;
    margin: 5px auto;
}

header .mobile .mobileLogin {
    margin: auto;
    width: 170px;
    text-align: center;
}

header .mobile .mobileLogin .line {
    display: inline-block;
    vertical-align: middle;
    height: 3px;
    width: 15px;
    background: #5e5e5f;
    border-radius: 10px;
    margin: 5px 10px;
}

header .mobile .mobileLogin li {
    list-style: none;
    margin-top: 35px;
    display: inline-block;
}

header .desktop ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
}

header .desktop ul li {
    display: inline;
}

header .desktop ul div.line {
    display: inline-block;
    vertical-align: middle;
    height: 3px;
    width: 17px;
    background: #5e5e5f;
    border-radius: 10px;
    margin: 0 10px;
}

@media only screen and (max-width: 840px) {
    header {
        display: block;
        position: fixed;
        z-index: 9;
        width: 100vw;
    }

    header .desktop ul {
        display: none !important;
    }

    header .mobile {
        display: block;
    }

    header .mobile .items {
        display: none;
    }

    header .mobile.active {
        display: block;
    }
}

header li {
    cursor: pointer;
}

header li a {
    color: #FFFFFF;
    text-decoration: none;
}

header li a.active {
    color: #3992f2 !important;
}

header .desktop ul:first-of-type {
    position: absolute;
    display: block;
    width: 30rem;
    margin-top: 35px;
    text-align: center;
    transform: translate(-50%);
    margin-right: 50%;
    margin-left: 50%;
}


header .desktop ul:last-of-type {
    text-align: center;
    width: 17rem;
    display: block;
    position: relative;
    margin-top: 35px;
    float: right;
}

/* Dropdown styles */
.dropdown {
    position: relative;
    padding: 0;
    margin-right: 1em;

    border: none;
}

.dropdown summary {
    list-style: none;
    list-style-type: none;
}

.dropdown > summary::-webkit-details-marker {
    display: none;
}

.dropdown summary:focus {
    outline: none;
}

.dropdown summary:focus div.button {
    border: 2px solid white;
}

.dropdown summary:focus {
    outline: none;
}

.mobile .dropdown ul {
    left: 50%;
    width: 90%;
    margin: 20px 0 0 calc((90% / 2) * -1);

}

.dropdown ul {
    position: absolute;
    padding: 20px 0;
    width: var(--dropdown-width);
    left: 100%;
    margin: 20px 0 0 calc((var(--dropdown-width) / 2) * -1);
    box-sizing: border-box;
    z-index: 2;

    background: var(--dropdown-background);
    border: 2px solid rgb(42, 41, 41);
    border-radius: 10px;
    list-style: none;
}

.mobile .dropdown ul li {
    margin: 5px auto;
    text-align: center;
}

.dropdown ul li {
    padding: 0;
    margin: 0;
}

.dropdown ul li div {
    display: inline-block;
    padding: 10px 0.8rem;
    width: 100%;
    box-sizing: border-box;

    color: var(--dropdown-color);
    text-decoration: none;
}

.dropdown ul li div:hover {
    color: var(--dropdown-color);
}

/* Dropdown triangle */
.dropdown ul::before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    top: -10px;
    left: 50%;
    margin-left: -10px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent var(--dropdown-background) transparent;
}


/* Close the dropdown with outside clicks */
.dropdown > summary::before {
    display: none;
}

.dropdown[open] > summary::before {
    content: ' ';
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
}

.menu-trigger img {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    background-color: #fff;
}

header .mobileLogin .dropdown-menu {
    top: 80px;
    background-color: var(--dropdown-color);
    border-radius: var(--border-radius);
    padding: 10px 20px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

header .dropdown-menu .partnerDropdown {
    margin-bottom: 15px !important;
    border-top: 2px solid #5e5e5f;
    border-bottom: 2px solid #5e5e5f;
}

header .dropdown-menu .adminDropdown {
    margin-bottom: 15px !important;
    border-top: 2px solid #5e5e5f;
    border-bottom: 2px solid #5e5e5f;
}

header .dropdown-menu .dropdownItem:first-of-type {
    border: none;
}

.dropdown-menu {
    position: absolute;
    top: 35px;
    right: 20px;
    background-color: var(--dropdown-color);
    border-radius: var(--border-radius);
    padding: 10px 20px;
    width: 200px;
    transition: var(--speed);
}

.dropdown-menu::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 110px;
    height: 20px;
    width: 20px;
    background: var(--secondary-bg);
    transform: rotate(45deg);
}

.dropdown-menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
}

.dropdown-menu.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: var(--speed) ease;
}

header .dropdown-menu ul {
    display: block !important;
    position: relative !important;
    width: 200px !important;
    padding: 0 !important;
    margin: 0 !important;
    float: none !important;
    transform: none !important;
}

.dropdown-menu ul li {
    display: block !important;
    padding: 10px 0;
    border-top: 1px solid #b5b5b5;
}

.dropdown-menu p.roleName {
    width: 100%;
    text-align: center;
    font-size: 18px;
    margin: 10px 0;
    font-weight: 500;
    font-size: 18px;
    color: var(--primary-text-color);
    line-height: 1.2rem;
}

.dropdown-menu p.roleName span {
    font-size: 14px;
    color: var(--secondary-text-color);
    font-weight: 400;
}

.dropdown-menu ul li:hover a {
    color: var(--secondary-text-color);
    cursor: pointer;
}

.dropdown-menu ul li:hover img {
    opacity: 1;
    cursor: pointer;
}

.dropdownItem {
    display: block;
    margin: 5px auto !important;
}

.dropdownItem img {
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    margin: 0 !important;
    transition: var(--speed);
}

.dropdownItem a {
    max-width: 100px;
    margin-left: 10px;
    margin-top-top: 10px;
    transition: var(--speed);
}

