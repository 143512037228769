@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #2A2929;
  padding: 0;
  margin: 0;
  color: #ffff;
  font-family: -apple-system, 'Roboto', SansSerif, serif !important;
  scrollbar-gutter: stable;
  overflow-x: hidden;
}

.mischpultImage {
  background: linear-gradient(rgb(22, 22, 22), transparent), linear-gradient(transparent, rgb(21, 21, 21)), url("./assets/img/mischpult.jpg") no-repeat center top;
  backdrop-filter: saturate(0%);
  background-blend-mode: normal;
  background-size: cover;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: rgb(42, 41, 41);
  border-radius: 10px;
  -moz-border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(88, 87, 87);
  border-radius: 10px;
}

.waves {
  height: 180px;
  width: 100%;
  z-index: 5;
  background: linear-gradient(rgba(255, 255, 255, 0.04), transparent), url("./assets/img/wave-1.svg") no-repeat center center,
  url("./assets/img/wave-2.svg") no-repeat center center,
  url("./assets/img/wave-3.svg") no-repeat center center;
  background-size: cover;
}

@media only screen and (min-width: 2000px) {
  .waves {
    height: 500px;
  }
}

div.top {
  background: rgba(255, 255, 255, 0.04);
  min-height: 85vh;
}

div.top::before {
  content: "";
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url("./assets/img/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.25;
  height: 100vh;
  z-index: -1;
}

.waves.rotate {
  rotate: 200grad;
  padding-top: 0;
  margin-top: 0;
  background: linear-gradient(transparent, transparent, transparent, transparent, transparent, rgba(21, 21, 21)), url("./assets/img/wave-1.svg") no-repeat center center,
  url("./assets/img/wave-2.svg") no-repeat center center,
  url("./assets/img/wave-3.svg") no-repeat center center;
  background-size: cover;
}

[type=range] {
  -webkit-appearance: none;
  margin: 15px 0;
  background: transparent;
  width: 95%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  border: 1px solid #2c2c2c;
  background: rgba(90, 90, 90, 0.5);
  border-radius: 60px;
}
input[type=range]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 90px;
  background: #5a5a5a;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4.6px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: rgba(90, 90, 90, 0.6);
}

.chat-logs {
  padding:15px;
  height:370px;
  overflow-y:scroll;
}


@media only screen and (max-width: 500px) {
  .chat-logs {
    height:40vh;
  }
}


.chat-msg.user > .msg-avatar img {
  width:45px;
  height:45px;
  border-radius:50%;
  float:left;
  width:15%;
}
.chat-msg.self > .msg-avatar img {
  width:45px;
  height:45px;
  border-radius:50%;
  float:right;
  width:15%;
}

.cm-msg-text {
  color: #ffffff;
  width:100%;
  float:left;
  margin-left:10px;
  position:relative;
  margin-bottom:5px;
  border-radius:30px;
}

.chat-msg .username {
  text-align: left;
  margin-left:10px;
  margin-bottom:20px;
  font-size: 14px;
}

.cm-msg-text .color {
  background: rgba(56, 56, 56, 0.52);
  max-width: 75%;
  float: left;
  padding:10px 15px 10px 15px;
  border-radius:30px;
}

.chat-msg {
  clear:both;
}
.chat-msg.self > .cm-msg-text {
  float:right;
  display: block;
  margin-right:10px;
  width: 100%;
  color:white;
}

.chat-msg.self > .username {
  text-align: right;
  margin-right:10px;
  margin-bottom:20px;
  font-size: 14px;
}

.chat-msg.self > .cm-msg-text .color {
  background: #5a5eb9;
  float: right;
}

.cm-msg-button>ul>li {
  list-style:none;
  float:left;
  width:50%;
}
.cm-msg-button {
  clear: both;
  margin-bottom: 70px;
}
